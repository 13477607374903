import React from "react";
import { graphql, PageProps } from "gatsby";
import { Form } from "../../components/forms";
import { Layout } from "../../components/layout";
import { Grid } from "../../components/rebrand/core/Grid/Grid";
import { FAQSection } from "../../components/rebrand/sections/FAQSection/FAQSection";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { Section } from "../../components/rebrand/sections/Section/Section";
import { SEO } from "../../components/seo";

export const pageQuery = graphql`
  query ContactPage {
    site {
      siteMetadata {
        pages {
          contact {
            title
            description
          }
        }
      }
    }
  }
`;

interface ContactPageProps extends PageProps {
  data: Queries.ContactPageQuery;
}

export default function ContactPage({ location: { pathname }, data: { site } }: ContactPageProps) {
  const { title, description } = site?.siteMetadata?.pages?.contact as Queries.SiteSiteMetadataPagesContact;

  const seo = {
    title: title as string,
    description: description as string,
    pathname,
  };

  return (
    <Layout>
      <SEO {...seo} />
      <PageHeaderSection title={title as string} subtitle='¿Tienes dudas? ¿Quieres saber más? Escríbenos o agenda una reunión aquí' />
      <Section paddingTopSpacing={[0, 0]} paddingBottomSpacing={[0, 0]}>
        <Grid gridTemplateColumns="repeat(12, 1fr)">
          <Form gridColumn={["1 / span 12", "1 / span 12", "4 / span 6"]} type="contact" />
        </Grid>
      </Section>
    </Layout>
  );
}
